
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
export const userColumns = [
  {
    Header: 'Legajo',
    accessor: 'legajo',
  },
  {
    Header: "Nombre",
    accessor: "nombre"
  },
  {
    Header: "Apellido",
    accessor: "apellido"
  },
  {
    Header: "Es Admin?",
    accessor: "rol_id",
    Cell: (props) => {
      const { value } = props
      if (value === 5) {
        return <CheckIcon ml={6} color={'green'} />
      }
      return <CloseIcon ml={6} color={'red'} />
    }
  },
];