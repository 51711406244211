import React, { useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button, Center, Checkbox, Input, VStack, FormControl, FormLabel, FormErrorMessage
} from '@chakra-ui/react'
import axios from 'axios';
import { Alertmsg } from '../'
import { usePath } from '../../contexts/PathContext';

export const ModalAdd = ({ isOpen, onOpen, onClose, refresh }) => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [url, setUrl] = useState('');
  const [imagen, setImagen] = useState(null);
  const [habilitado, setHabilitado] = useState('');
  const [error, setError] = useState('');
  const [errorImg, setErrorImg] = useState('');
  const [code, setCode] = useState(null);
  const [msg, setMsg] = useState(null);

  const headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
  };

  const setNull = () => {
    setUrl('')
    setImagen(null)
    setHabilitado('')
  }

  const cerrarModal = () => {
    setNull()
    onClose()
  }

  const valUrl = useCallback(() => {
    const urlPattern = /^(http|https):\/\/.*\.[^.]+$/i;
    if (!urlPattern.test(url)) {
      setError('URL inválida. Debe empezar con "http://" or "https://" y contener ".com", ".net", etc.');
      return false;
    } else {
      setError('')
      return true;
    }
  }, [url]);

  const valImg = useCallback(() => {
    if (!imagen) {
      setErrorImg('Debe seleccionar una imagen');
      return false;
    } else {
      setErrorImg('')
      return true;
    }
  }, [imagen]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const validURL = valUrl();
    const validIMG = valImg();

    if (validURL && validIMG) {
      event.preventDefault();

      const formData = new FormData();
      formData.append('img', imagen);
      formData.append('url', url);
      formData.append('habilitado', habilitado ? 1 : 0);
      try {
        const response = await axios.post(`${BASE_URL}/api/publicidad/add`, formData, { headers })

        const { message } = response.data

        if (message === "publicidadAddOk") {
          setMsg('Publicidad creada con exito');
          setCode('success')
          setNull()

          refresh()
          onClose()

        } else {
          setMsg('No se pudo crear la publicidad')
          setCode('error');
          setNull()
        }
      } catch (err) {
        console.error(err);
      }
    };
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={cerrarModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Añadir publicidad</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Alertmsg msg={msg} code={code} />
              <form onSubmit={handleSubmit}>
                {/* URL Input */}
                <FormControl isInvalid={error}>
                  <FormLabel htmlFor="url">URL</FormLabel>
                  <Input
                    type="text"
                    id="url"
                    placeholder="Enter URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>

                {/* Image Upload */}
                <FormControl isInvalid={errorImg}>
                  <FormLabel htmlFor="img">Imagen</FormLabel>
                  <Input
                    type="file" name="img"
                    id="img"
                    onChange={(e) => setImagen(e.target.files[0])}
                  />
                  <FormErrorMessage>{errorImg}</FormErrorMessage>
                </FormControl>

                <Center>
                  <Checkbox onChange={(e) => setHabilitado(e.target.checked)} mt='10' mb='5' size='md' colorScheme='green'>
                    Habilitar publicidad
                  </Checkbox>
                </Center>

                {/* Submit Button */}
                <Button type="submit" mt={4} colorScheme="blue">
                  Guardar
                </Button>
              </form>
            </VStack>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

