import { Box, Button, Tooltip } from "@chakra-ui/react";
import { FaRegFileExcel } from "react-icons/fa";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export const ExportExcel = ({ excelData, fileName }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToExcel = async () => {
        let datos = structuredClone(excelData);
        datos.forEach((e) => {
            delete e.eventos_tipos;
            delete e.eventos_usuarios;
            delete e.id_usuario;
            delete e.tipo_id;
        });

        const ws = XLSX.utils.json_to_sheet(datos);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <>
            <Tooltip>
                <Button
                    mt={3}
                    variant="contained"
                    color="primary"
                    style={{ cursor: "pointer", fontSize: 14 }}
                    onClick={(e) => exportToExcel(fileName)}
                >
                    <Box>
                        <FaRegFileExcel
                            style={{ width: 30, height: 30, color: "green" }}
                        />
                    </Box>
                </Button>
            </Tooltip>
        </>
    );
};
