import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'

const Alertmsg = ({ msg, code }) => {

  return (
    <>
      {code && <Alert
        status={code}
        mb={4}
        variant='subtle'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
      >
        <AlertIcon />
        <AlertTitle >{msg}</AlertTitle>
      </Alert>}
    </>
  )
}

export default Alertmsg