import { createContext, useContext, useMemo } from "react";

const PathContext = createContext();

export const PathProvider = ({ children }) => {

  const CheckEnv = () => {
    console.log(process.env.REACT_APP_ENV)
    if (process.env.REACT_APP_ENV !== 'production') {
      return 'https://dev.cdssoftware.com.ar/moron-btn-mobile'
    } else {
      return 'https://btn-mobile.cdssoftware.com.ar'
    }
  }
  const value = useMemo(
    () => ({
      CheckEnv
    }),
    []
  );
  return <PathContext.Provider value={value}>{children}</PathContext.Provider>;
};

export const usePath = () => {
  return useContext(PathContext);
};
