import React, { useState, useEffect } from "react";
import axios from "axios";
import { userColumns } from "./columns";
import { useTableSearch } from "./useTableSearch";
import {
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Button,
  Select,
} from "@chakra-ui/react";
import { ExportExcel } from "../../assets/excelExport";
import { Search2Icon } from "@chakra-ui/icons";
import { useSessionStorage } from "../../assets/useSessionStorage";
import ReactHowler from "react-howler";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Center,
  Box,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, useRowSelect } from "react-table";
import Alertmsg from "../Alertmsg";
import { usePath } from "../../contexts/PathContext";

const Tabla = ({
  eventos,
  onSelectRow,
  onOpen,
  onClose,
  isOpen,
  playSound,
  setPlaySound,
  sinSonido,
}) => {
  const [nombre, setNombre] = useState("");
  const [fecha, setFecha] = useState("");
  const [evento, setEvento] = useState("");
  const [dni, setDni] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [dirCalle, setDirCalle] = useState("");
  const [dirNro, setDirNro] = useState("");

  const [code, setCode] = useState(null);
  const [msg, setMsg] = useState(null);
  const { CheckEnv } = usePath();
  const BASE_URL = CheckEnv();

  const [searchVal, setSearchVal] = useState(null);
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = useState(null);
  // eslint-disable-next-line
  const [selected, setSelected] = useSessionStorage("tabla", null);

  async function advancedSerch() {
    // tipo_evento dir_calle dir_nro /getEventFiltered

    try {
      const response = await axios.get(
        `${BASE_URL}/api/event/getEventFiltered/`,
        {
          nombre: nombre,
          fecha: fecha,
          tipo_evento: evento,
          localidad: localidad,
          dir_calle: dirCalle,
          dir_nro: dirNro,
        }
      );

      const { message } = response.data;
      if (message === "findEventosOK") {
        setMsg("Eventos encontrados");
        setCode("success");
      } else {
        setMsg("Error en la busqueda de Eventos");
        setCode("error");
      }
    } catch (err) {
      console.error(err);
    }
    console.log(
      "Estas buscandoo pito con estas caracteristicas ",
      nombre,
      " ",
      fecha,
      " de ",
      evento,
      " ",
      dni,
      " ",
      localidad,
      " ",
      dirCalle,
      " ",
      dirNro
    );
  }

  const { filteredData } = useTableSearch({
    searchVal,
    retrieve: eventos,
  });

  const data = React.useMemo(() => filteredData, [filteredData]);

  const columns = React.useMemo(() => userColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      stateReducer: (state, action) => {
        if (
          action.type === "toggleRowSelected" &&
          Object.keys(state.selectedRowIds).length
        ) {
          const newState = { ...state };
          newState.selectedRowIds = {
            [action.id]: true,
          };
          return newState;
        }
        return state;
      },
    },
    useSortBy,
    useRowSelect
  );

  let estado = (data) => {
    //#C7E9B0 Verde
    //#FFACAC Rojo
    //#F0EB8D Amarillo
    if (data.cells[4].value === "PENDIENTE") {
      return "#FFACAC";
    }
    if (data.cells[4].value === "EN CURSO") {
      return "#F0EB8D";
    }
    if (data.cells[4].value === "CERRADO") {
      return "#C7E9B0";
    }
  };

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      setSelectedRow(selectedFlatRows[0].original);
      setSelected(selectedFlatRows[0].original);
      onSelectRow(selectedFlatRows[0].original);
    } else if (selected) {
      const selectedRow = filteredData.find(
        (event) => event.id === selected.id
      );
      const selectedTable = rows.find(
        (event) => event.original.id === selected.id
      );
      setSelectedRow(selectedRow);
      onSelectRow(selectedRow);
      if (selectedTable) {
        selectedFlatRows.push(selectedTable);
      }
    } else {
      setSelectedRow(null);
      onSelectRow(null);
    }
  }, [
    filteredData,
    onSelectRow,
    selected,
    selectedFlatRows,
    setSelected,
    rows,
    onOpen,
  ]);

  useEffect(() => {
    if (playSound) {
      setTimeout(() => {
        setPlaySound(false);
      }, 9000);
    }
  }, [playSound, setPlaySound]);

  return (
    <>
      <HStack spacing="24px">
        <Alertmsg msg={msg} code={code} />
        <Box w={"9%"}></Box>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            m={3}
            children={<Search2Icon color="gray.300" />}
          />
          <Input
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder="Buscar..."
            _placeholder={{ color: "gray.500" }}
            m={3}
            type="search"
            w={"15rem"}
            enterbutton="true"
            style={{ position: "sticky", top: "0", left: "0" }}
            boxShadow="2xl"
            focusBorderColor="#c80f2e"
            borderColor="gray.400"
          />

          <ExportExcel
            excelData={filteredData}
            fileName={searchVal !== null ? `eventos ${searchVal}` : "eventos"}
          />
        </InputGroup>
      </HStack>

      <Center>
        <Box
          h={isOpen ? 300 : 550}
          style={{ overflow: "auto", width: "80%" }}
          border="2px"
          borderColor="gray.300"
          variant="simple"
          boxShadow="2xl"
        >
          <Stack>
            <Accordion allowMultiple>
              <AccordionItem>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Busqueda Avanzada
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <HStack>
                    <Input
                      value={nombre}
                      mb={5}
                      variant="flushed"
                      placeholder="Nombre"
                      onChange={(e) => setNombre(e.target.value)}
                    />
                    <Input
                      value={fecha}
                      mb={5}
                      variant="flushed"
                      placeholder="Fecha"
                      onChange={(e) => setFecha(e.target.value)}
                    />

                    <Select
                      mb={5}
                      placeholder="Tipo de evento"
                      variant="flushed"
                      onChange={(e) => setEvento(e.target.value)}
                    >
                      <option value="0">Policia</option>
                      <option value="1">Bombero</option>
                      <option value="2">Salud</option>
                    </Select>
                  </HStack>
                  <HStack>
                    <Input
                      value={dni}
                      mb={5}
                      variant="flushed"
                      placeholder="DNI"
                      onChange={(e) => setDni(e.target.value)}
                    />
                    <Input
                      value={localidad}
                      mb={5}
                      variant="flushed"
                      placeholder="Localidad del ciudadano"
                      onChange={(e) => setLocalidad(e.target.value)}
                    />
                    <Input
                      value={dirCalle}
                      mb={5}
                      variant="flushed"
                      placeholder="Calle"
                      onChange={(e) => setDirCalle(e.target.value)}
                    />
                    <Input
                      value={dirNro}
                      mb={5}
                      variant="flushed"
                      placeholder="Altura"
                      onChange={(e) => setDirNro(e.target.value)}
                    />
                  </HStack>

                  <Button
                    colorScheme="red"
                    variant="solid"
                    onClick={() => {
                      advancedSerch();
                    }}
                  >
                    Buscar
                  </Button>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      isNumeric={column.isNumeric}
                    >
                      {column.render("Header")}
                      <chakra.span pl="4">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <Tr
                    style={{ "--animate-duration": "3s" }}
                    className={
                      row.id === "0" &&
                      row.cells[4].value === "PENDIENTE" &&
                      playSound
                        ? `animate__animated animate__flash animate__repeat-3`
                        : ""
                    }
                    bg={!row.isSelected ? estado(row) : "#8EA7E9"}
                    color={!row.isSelected ? "black" : "white"}
                    {...row.getRowProps()}
                    onClick={() => {
                      row.toggleRowSelected();
                      !row.isSelected ? onOpen() : onClose();
                    }}
                  >
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {!sinSonido && (
            <ReactHowler
              src="audio.mp3"
              playing={playSound && true}
              preload={true}
            />
          )}
        </Box>
      </Center>
    </>
  );
};

export default Tabla;
