import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@chakra-ui/react";
import { usePath } from "../contexts/PathContext";

export const UpdateList = ({ refresh, setPlaySound }) => {
    const [data, setData] = useState(null);
    const [flag, setFlag] = useState(false);
    const { CheckEnv } = usePath();

    const BASE_URL = CheckEnv();



    useEffect(() => {
        const interval = setInterval(() => {
            axios
                .get(
                    `${BASE_URL}/api/event/${20}`
                )
                .then((response) => {
                    if (!data || response.data.data[0].id !== data[0].id) {
                        setData(response.data.data);
                        if (flag) {
                            refresh();
                            setPlaySound(true)
                        }
                        setFlag(true);
                    }
                })
                .catch((error) => {
                    console.log("Error:", error);
                });
        }, 10000);

        return () => clearInterval(interval);
    }, [data, refresh, flag, BASE_URL, setPlaySound]);
    return <Box></Box>;
};
