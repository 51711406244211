import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Center, Image } from '@chakra-ui/react'
import { usePath } from '../../contexts/PathContext';

export const userColumns = [
  {
    Header: "URL",
    accessor: "url"
  },
  {
    Header: "Imagen",
    accessor: "img",
    Cell: (props) => {
      const { CheckEnv } = usePath()
      const BASE_URL = CheckEnv();

      const { value } = props

      const uri = `${BASE_URL}${value}`
      return <Center><Image src={uri} alt="Logo" h="80px" /></Center>
    }
  },
  {
    Header: "Habilitado",
    accessor: "habilitado",
    Cell: (props) => {
      const { value } = props
      if (value === 1) {
        return <CheckIcon color={'green'} />
      }
      return <CloseIcon color={'red'} />
    }
  },
];