import React, { useState } from 'react';
import {
  Button,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'

import axios from 'axios';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Alertmsg } from '../';
import { useSessionStorage } from '../../assets/useSessionStorage';
import { usePath } from '../../contexts/PathContext';


const PwdChangeUser = ({ onOpen, onClose, isOpen }) => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [code, setCode] = useState(null);
  const [msg, setMsg] = useState(null);
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const handleClick = () => setShow(!show)
  const handleClick1 = () => setShow1(!show1)
  const handleClick2 = () => setShow2(!show2)
  const [legajoLogin] = useSessionStorage("legajo")

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [password3, setPassword3] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    let legajo = legajoLogin

    try {

      const response = await axios.put(`${BASE_URL}/api/login/resetPass/${legajo}`,
        { 'password1': password1, 'password2': password2, 'passOld': password3 })
      const { message } = response.data

      if (message === "userResetPassOK") {
        setMsg('Cambio de contraseña realizado');
        setCode('success')

      } else {
        setMsg('Error al cambiar la contraseña')
        setCode('error');
      }
      setPassword1('')
      setPassword2('')
      setPassword3('')
    } catch (err) {
      console.error(err);
    }

  };

  return (
    <>
      <VStack>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cambio de Contraseña</ModalHeader>
            <Alertmsg msg={msg} code={code} />
            <ModalCloseButton />
            <ModalBody pb={6}>
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <InputGroup size='md'>
                    <Input
                      value={password3}
                      pr='4.5rem'
                      type={show ? 'text' : 'password'}
                      placeholder='Contraseña actual'
                      onChange={(e) => setPassword3(e.target.value)}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button h='1.75rem' size='sm' onClick={handleClick}>
                        {show ? <HiEye /> : <HiEyeOff />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size='md'>
                    <Input
                      value={password2}
                      pr='4.5rem'
                      type={show1 ? 'text' : 'password'}
                      placeholder='Nueva Contraseña'
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button h='1.75rem' size='sm' onClick={handleClick1}>
                        {show1 ? <HiEye /> : <HiEyeOff />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <InputGroup size='md'>
                    <Input
                      value={password1}
                      pr='4.5rem'
                      type={show2 ? 'text' : 'password'}
                      placeholder='Repetir Nueva Contraseña'
                      onChange={(e) => setPassword1(e.target.value)}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button h='1.75rem' size='sm' onClick={handleClick2}>
                        {show2 ? <HiEye /> : <HiEyeOff />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button mt={5} type='submit' colorScheme='blue' mr={3}>
                  Guardar
                </Button>
              </form>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </>
  )
}

export default PwdChangeUser