
import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  ModalBody,
  useDisclosure,
  Icon,
  Link,
  Tooltip,
  Text,
  Flex,
  Image,
  OrderedList,
  ListItem,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons'
import logo from "../../img/logo_moron.png";
import ReactToPrint from 'react-to-print';

const Imprimir = React.forwardRef(({ detalles, observaciones }, ref) => {
  const data = detalles;
  const { eventos_usuarios } = data;
  const formatText = (text) => {
    return JSON.stringify(text).replace(/"/g, "");
  };
  const listObs = observaciones ? (
    observaciones.map((obs) => (
      <Box px={5} py={2} mt={2} key={obs.fecha} mb={2}>
        <ListItem mb={2}>
          <HStack>
            <Text>{formatText(obs.fecha)}</Text>
            <Text as="b" mr={2}>
              Legajo:{" "}
            </Text>
            <Text>{formatText(obs.legajo)}</Text>
            <Text as="b" mr={2}>
              Estado:{" "}
            </Text>
            <Text >{formatText(obs.estado)}</Text>
          </HStack>
        </ListItem>
        <HStack>
          <Text>{formatText(obs.texto)}</Text>
        </HStack>
      </Box>
    ))
  ) : (
    <ListItem>No hay observaciones</ListItem>
  );

  return (
    <div ref={ref}>
      <Box>
        <Box>
          <Flex bg="#c80f2e">
            <Box bg="#c80f2e" w="100%" h="6.5rem" p={2} color="white">
              <Box>
                <Link to="/home">
                  <Image src={logo} alt="Logo" h="80px" />
                </Link>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Center w={"100%"}>
          <Box>
            <Table __css={{ 'table-layout': 'fixed', width: 'full' }} variant='striped' size='sm' colorScheme='red'>
              <Thead>
                <Tr>
                  <Th>Evento ID</Th>
                  <Th>Evento Tipo</Th>
                  <Th>Nombre</Th>
                  <Th>Calle</Th>
                  <Th>Altura</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{data.id}</Td>
                  <Td>{data.tipoEvento}</Td>
                  <Td>{data.nombreCompleto}</Td>
                  <Td>{eventos_usuarios.dir_calle}</Td>
                  <Td>{eventos_usuarios.dir_altura}</Td>
                </Tr>
              </Tbody>
            </Table>
            <Table __css={{ 'table-layout': 'fixed', width: 'full' }} variant='striped' size='sm' colorScheme='red'>
              <Thead>
                <Tr>
                  <Th>Localidad</Th>
                  <Th>Evento Calle</Th>
                  <Th>Evento Altura</Th>
                  <Th>DNI</Th>
                  <Th>Email</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{eventos_usuarios.dir_localidad}</Td>
                  <Td>{data.dir_calle}</Td>
                  <Td>{data.dir_nro}</Td>
                  <Td>{eventos_usuarios.dni}</Td>
                  <Td>{eventos_usuarios.email}</Td>
                </Tr>
              </Tbody>
            </Table>
            <Table __css={{ 'table-layout': 'fixed', width: 'full' }} variant='striped' size='sm' colorScheme='red'>
              <Thead>
                <Tr>
                  <Th>Tel</Th>
                  <Th>Tel Ref</Th>
                  <Th>Per Ref</Th>
                  <Th>Tel Alt</Th>
                  <Th>Genero</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{eventos_usuarios.telefono}</Td>
                  <Td>{eventos_usuarios.telefono_persona_referencia}</Td>
                  <Td>{eventos_usuarios.persona_referencia}</Td>
                  <Td>{eventos_usuarios.telefono_alternativo}</Td>
                  <Td>{eventos_usuarios.genero}</Td>
                </Tr>
              </Tbody>
            </Table>
            <OrderedList mb={2}>{listObs}</OrderedList>
          </Box>
        </Center>
      </Box>
    </div>
  )
})



const ViewPrint = ({ detalles, observaciones }) => {
  let componentRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box mt={2}>
      <Tooltip label='Imprimir pdf'>
        <Link>
          <Icon color={"green"} w={6} h={6} onClick={onOpen} as={DownloadIcon} />
        </Link>
      </Tooltip>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset='slideInBottom'
        size={'xxl'}
        value='outside'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Imprimir detalles={detalles} observaciones={observaciones} ref={el => (componentRef = el)} scale={0.95} />
            <ReactToPrint
              trigger={() => <Button colorScheme="blue">Imprimir PDF</Button>}
              content={() => componentRef}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default ViewPrint