import React, { useMemo, useState, useEffect } from 'react'
import axios from 'axios';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra, Box, Center
} from '@chakra-ui/react'
import { userColumns } from "./columns";
import { useTableSearch } from "./useTableSearch";
import { Input, InputGroup, InputLeftElement, HStack } from "@chakra-ui/react";
import { Search2Icon } from '@chakra-ui/icons'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { useTable, useSortBy } from 'react-table'
import { usePath } from '../../contexts/PathContext';

const SignedUpUserList = () => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [searchVal, setSearchVal] = useState(null);
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await axios.get(`${BASE_URL}/api/user/`)
        .then(async (response) => {

          setUsuarios(response.data.data)
        })
        .catch(error => {
          console.log("Error", error);
        })
    }
    fetchData()
  }, [setUsuarios, BASE_URL])

  const { filteredData } = useTableSearch({
    searchVal,
    retrieve: usuarios
  });

  const data = useMemo(
    () => filteredData,
    [filteredData],
  )

  const columns = useMemo(
    () => userColumns,
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  return (
    <>
      <Box w={1400} p={3}>

        <HStack spacing='24px'>
          <Box w='9rem' h='5' />
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              m={3}
              children={<Search2Icon color='gray.300' />}
            />
            <Input
              onChange={e => setSearchVal(e.target.value)}
              placeholder="Buscar..."
              _placeholder={{ color: 'gray.500' }}
              m={3}
              type="search"
              w={'15rem'}
              enterbutton="true"
              style={{ position: "sticky", top: "0", left: "0" }}
              boxShadow='2xl'
              focusBorderColor='#c80f2e'
              borderColor='gray.400'
            />
          </InputGroup>
        </HStack>
        <Center>
          <Box style={{ overflow: "auto", width: '80%' }}
            border='2px'
            borderColor='gray.300'
            variant='simple'
            boxShadow='2xl'
            borderRadius='lg'
          >
            <Table variant='striped' colorScheme='red' {...getTableProps()}>
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        isNumeric={column.isNumeric}
                      >
                        {column.render('Header')}
                        <chakra.span pl='4'>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label='sorted descending' />
                            ) : (
                              <TriangleUpIcon aria-label='sorted ascending' />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <Tr
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <Td {...cell.getCellProps()}>
                          {cell.render('Cell')}

                        </Td>
                      ))}

                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </Box>
        </Center>
      </Box>
    </>
  )
}

export default SignedUpUserList