import React, { useCallback, useEffect, useState } from "react";
import { Details, Tabla, Map } from "../../components/";
import { useSessionStorage } from "../../assets/useSessionStorage";
import { UpdateList } from "../../assets/updateList";
import axios from "axios";
import {
    Collapse,
    Box,
    useDisclosure,
    Center,
    Card,
    CardBody,
    SimpleGrid, Button
} from "@chakra-ui/react";
import { usePath } from "../../contexts/PathContext";

const Home = () => {
    const { CheckEnv } = usePath()
    const BASE_URL = CheckEnv()


    const [eventosList, setEventosList] = useState([]);
    const [newList, setNewList] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [playSound, setPlaySound] = useState(false);
    const [selected, setSelected] = useSessionStorage("tabla", null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleSelectRow = useCallback((row) => {
        setSelectedRow(row);
    }, []);

    const handleMedia = useCallback((bool) => {
        setPlaySound(bool);
    }, []);

    const handleRefresh = useCallback(() => {
        setSelected(selectedRow);
        setRefresh(true);
    }, [selectedRow, setSelected]);

    const handleSound = useCallback(() => {
        setPlaySound(true)
        handleRefresh()
    }, [handleRefresh]);

    useEffect(() => {
        if (selected === null) {
            onClose();
        }
    }, [onClose, selected]);

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(
                    `${BASE_URL}/api/event/${20}`
                )
                .then(async (response) => {
                    setEventosList(response.data.data);
                    setRefresh(false);
                })
                .catch((error) => {
                    console.log("Error", error);
                });
        }
        fetchData();
    }, [setEventosList, refresh, BASE_URL]);

    useEffect(() => {
        const isInList = (id) => {
            return eventosList.find((item) => item.id === id);
        };

        const updateList = async () => {
            const updatedList = await Promise.all(
                eventosList.map(async (item) => {
                    const dup = isInList(item.id);
                    if (dup) {
                        const { eventos_usuarios, eventos_tipos } = dup;
                        return {
                            ...dup,
                            nombreCompleto: `${eventos_usuarios.nombre} ${eventos_usuarios.apellido}`,
                            tipoEvento: `${eventos_tipos.nombre}`,
                        };
                    }
                    return null;
                })
            );
            const filteredList = updatedList.filter((item) => item !== null);
            setNewList(filteredList);
        };

        updateList();
    }, [eventosList]);

    return (
        <>
            <Button onClick={handleMedia} hidden>play sound</Button>
            <Collapse in={isOpen} animateOpacity>
                <Center p={5}>
                    <Card
                        w="80rem"
                        h="20rem"
                        border="1px"
                        borderColor="gray.200"
                        boxShadow="xl"
                        rounded="md"
                        bg="white"
                        zIndex={2}
                    >
                        <CardBody>
                            <SimpleGrid
                                columns={3}
                                spacingX="50px"
                                spacingY="10px"
                            >
                                <Box
                                    border="2px"
                                    w="100%"
                                    h="17.9rem"
                                    borderColor="grey"
                                >
                                    {selectedRow ? (
                                        <Map
                                            lat={selectedRow.geo_lat}
                                            lng={selectedRow.geo_lon}
                                        />
                                    ) : (
                                        "No se encuentra la ubicación"
                                    )}
                                </Box>
                                {selectedRow && (
                                    <Details
                                        detalles={selectedRow}
                                        conObs={true}
                                        obs={null}
                                        refresh={handleRefresh}
                                        cerrarObs={onClose}
                                    />
                                )}
                            </SimpleGrid>
                        </CardBody>
                    </Card>
                </Center>
            </Collapse>
            <Box mt={!isOpen ? "2rem" : "0rem"}>
                <Tabla
                    eventos={newList}
                    onSelectRow={handleSelectRow}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    playSound={playSound}
                    setPlaySound={handleMedia}
                    sinSonido={false}
                />
                <UpdateList refresh={handleSound} setPlaySound={handleMedia} />
            </Box>
        </>
    );
};

export default Home;
