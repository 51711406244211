export const userColumns = [
    {
        Header: "Email",
        accessor: "email",
    },
    {
        Header: "Nombre",
        accessor: "nombre",
    },
    {
        Header: "Apellido",
        accessor: "apellido",
    },
    {
        Header: "DNI",
        accessor: "dni",
    },
    {
        Header: "Telefono",
        accessor: "telefono",
    },
];
