import React, { useMemo, useState, useEffect } from 'react'
import axios from 'axios';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra, Box, Center, Button, IconButton,
  useDisclosure
} from '@chakra-ui/react'
import { userColumns } from "./columns";
import { useTableSearch } from "./useTableSearch";
import { Input, InputGroup, InputLeftElement, HStack } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon, ViewIcon, ViewOffIcon, AddIcon, Search2Icon, MinusIcon } from '@chakra-ui/icons'
import { useTable, useSortBy } from 'react-table'
import { ModalAdd } from './modal';
import { useCallback } from 'react';
import { usePath } from '../../contexts/PathContext';


const Publi = () => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [searchVal, setSearchVal] = useState(null);
  const [publicidad, setPublicidad] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleRefresh = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headersFull = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      };
      await axios.get(`${BASE_URL}/api/publicidad/getFull`, { headersFull })
        .then(async (response) => {
          setPublicidad(response.data.data)
        })
        .catch(error => {
          console.log("Error", error);
        })
    }
    if (refresh) {
      fetchData()
      setRefresh(false)
    }
  }, [setPublicidad, refresh, BASE_URL])

  const { filteredData } = useTableSearch({
    searchVal,
    retrieve: publicidad
  });

  const data = useMemo(
    () => filteredData,
    [filteredData],
  )

  const handleButtonClick = useCallback(async (id, estado) => {
    const headers = {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    };
    if (estado) {
      await axios.put(`${BASE_URL}/api/publicidad/disable`, { id: id }, { headers })
        .then(async (response) => {
          setRefresh(true)
        })
        .catch(error => {
          console.log("Error", error);
        })
    } else {
      await axios.put(`${BASE_URL}/api/publicidad/enable`, { id: id }, { headers })
        .then(async (response) => {
          setRefresh(true)
        })
        .catch(error => {
          console.log("Error", error);
        })
    }
  }, [BASE_URL]);

  const handleButtonDelete = useCallback(async (id) => {
    const headers = {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    };
    await axios.delete(`${BASE_URL}/api/publicidad/delete`, { id: id }, { headers })
      .then(async (response) => {
        setRefresh(true)
      })
      .catch(error => {
        console.log("Error", error);
      })
  }, [BASE_URL]);

  const columns = useMemo(
    () =>
      [...userColumns,
      {
        Header: "Habilitar/Deshabilitar",
        Cell: (props) => {
          const { habilitado } = props.row.original
          const { id } = props.row.original
          return (
            <>
              {habilitado ?
                <IconButton
                  colorScheme='red'
                  aria-label='Deshabilitar'
                  icon={<ViewOffIcon />}
                  onClick={() => handleButtonClick(id, habilitado)}
                />
                :
                <IconButton
                  colorScheme='green'
                  aria-label='Deshabilitar'
                  icon={<ViewIcon />}
                  onClick={() => handleButtonClick(id, habilitado)}
                />
              }
            </>
          )
        }
      },
      {
        Header: "Eliminar",
        Cell: (props) => {
          const { id } = props.row.original
          return (
            <>
              <IconButton
                colorScheme='red'
                aria-label='Deshabilitar'
                icon={<MinusIcon />}
                onClick={() => handleButtonDelete(id)}
              />
            </>
          )
        }
      },
      ],
    [handleButtonClick, handleButtonDelete],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  return (
    <>
      <Center w={"100%"}>
        <Box w={"80%"} p={3} mb={10}>
          <HStack spacing='24px'>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                m={3}
                children={<Search2Icon color='gray.300' />}
              />
              <Input
                onChange={e => setSearchVal(e.target.value)}
                placeholder="Buscar..."
                _placeholder={{ color: 'gray.500' }}
                m={3}
                type="search"
                w={'15rem'}
                enterbutton="true"
                style={{ position: "sticky", top: "0", left: "0" }}
                boxShadow='2xl'
                focusBorderColor='#c80f2e'
                borderColor='gray.400'
              />
            </InputGroup>
            <Button rightIcon={<AddIcon />} colorScheme='blue' p={5} onClick={onOpen}>
              Añadir publicidad
            </Button>
          </HStack>
          <Center>
            <Box style={{ overflow: "auto", width: '100%' }}
              border='2px'
              borderColor='gray.300'
              variant='simple'
              boxShadow='2xl'
              borderRadius='lg'
            >
              <Table variant='striped' colorScheme='red' {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <Th textAlign="center"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          isNumeric={column.isNumeric}
                        >
                          {column.render('Header')}
                          <chakra.span pl='4'>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <TriangleDownIcon aria-label='sorted descending' />
                              ) : (
                                <TriangleUpIcon aria-label='sorted ascending' />
                              )
                            ) : null}
                          </chakra.span>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row)
                    return (
                      <Tr
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => (
                          <Td textAlign="center" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </Td>
                        ))}
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </Box>
          </Center>
        </Box>
      </Center>
      <ModalAdd onClose={onClose} onOpen={onOpen} isOpen={isOpen} refresh={handleRefresh} />
    </>
  )
}

export default Publi