import React from "react";
import {
    Button,
    Box,
    FormControl,
    FormLabel,
    Input,
    Center,
    Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import { Alertmsg } from "../../components";
import { useState } from "react";
import { usePath } from "../../contexts/PathContext";

const Unsub = () => {
    const [nombre, setNombre] = useState("");
    const [telefono, setTelefono] = useState("");
    const [motivo, setMotivo] = useState("");
    const [email, setEmail] = useState("");
    const { CheckEnv } = usePath();
    const BASE_URL = CheckEnv();
    const [code, setCode] = useState(null);
    const [msg, setMsg] = useState(null);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setMsg(null);
        const data = {
            nombre: nombre,
            telefono: telefono,
            motivo: motivo,
            email: email,
        };

        try {
            const response = await axios.post(
                `${BASE_URL}/api/user/unsub`,
                data
            );
            const { message } = response.data;

            if (message === "unsSuccess") {
                setMsg("Cuenta Borrada");
                setCode("success");
            } else {
                setMsg("Problemas al eliminar la cuenta");
                setCode("error");
            }
        } catch (err) {
            console.error(err);
            setMsg(
                "Hubo un error al intentar loguearse, intente nuevamente más tarde"
            );
            setCode("error");
        }
    };

    return (
        <Box p={100}>
            <Alertmsg msg={msg} code={code} />
            <Center>
                <form onSubmit={handleSubmit}>
                    <FormControl isRequired>
                        <FormLabel>Nombre y Apellido</FormLabel>
                        <Input
                            mt={5}
                            placeholder="Nombre y Apellido"
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <FormLabel>Correo Electronico</FormLabel>
                        <Input
                            mt={5}
                            placeholder="Correo Electronico"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormLabel mt={5}>Numero de Telefono</FormLabel>
                        <Input
                            mt={5}
                            placeholder="Numero de Telefono"
                            onChange={(e) => setTelefono(e.target.value)}
                        />
                        <Textarea
                            mt={5}
                            placeholder="Motivo de la baja"
                            onChange={(e) => setMotivo(e.target.value)}
                        />
                        <Button colorScheme="red" type="submit" mt={5}>
                            Guardar
                        </Button>
                    </FormControl>
                </form>
            </Center>
        </Box>
    );
};

export default Unsub;
