import {
  Button,
  Center,
  Input,
  Stack,
  HStack,
  VStack,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import axios from "axios";
import { Alertmsg } from "../";
import { usePath } from "../../contexts/PathContext";

const EditUser = () => {
  const { CheckEnv } = usePath();
  const BASE_URL = CheckEnv();

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [legajo, setLegajo] = useState("");
  const [code, setCode] = useState(null);
  const [msg, setMsg] = useState(null);
  const [rol, setRol] = useState("");

  const searchSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get(`${BASE_URL}/api/login/${legajo}`);
      const { data } = response.data;

      if (data.length !== 0) {
        setNombre(data[0].nombre);
        setApellido(data[0].apellido);
      }
      const { message } = response.data;

      if (message === "loginFindOK") {
        setMsg("Legajo encontrado");
        setCode("success");
      } else {
        setMsg("Legajo no encontrado");
        setCode("error");
        setNombre("");
        setApellido("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handDelete = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/login/delete/${legajo}`
      );

      const { message } = response.data;
      if (message === "loginDeleteOK") {
        setMsg("Usuario eliminado con exito");
        setCode("success");
        setApellido("");
        setLegajo("");
        setNombre("");
      } else {
        setMsg("Error al eliminar Usuario");
        setCode("error");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(
        `${BASE_URL}/api/login/update/${legajo}`,
        { nombre: nombre, apellido: apellido, rol_id: rol }
      );

      const { message } = response.data;
      if (message === "loginUpdateOk") {
        setMsg("Usuario modificado con exito");
        setCode("success");
      } else {
        setMsg("Error al modificar Usuario");
        setCode("error");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Stack>
        <Alertmsg msg={msg} code={code} />
        <form onSubmit={searchSubmit}>
          <Center>
            <HStack ml={5} mt={5} mb={5} spacing={3}>
              <Input
                w={200}
                value={legajo}
                ml={20}
                mr={2}
                variant="flushed"
                placeholder="Legajo"
                onChange={(e) => setLegajo(e.target.value)}
              />
              <Button type="submit" colorScheme="red" variant="solid">
                Buscar
              </Button>
            </HStack>
          </Center>
        </form>
        <form onSubmit={handleSubmit}>
          <Center>
            <VStack mt={5} spacing={3}>
              <Input
                value={nombre}
                mb={5}
                variant="flushed"
                placeholder="Nombre"
                onChange={(e) => setNombre(e.target.value)}
              />
              <Input
                value={apellido}
                mb={5}
                variant="flushed"
                placeholder="Apellido"
                onChange={(e) => setApellido(e.target.value)}
              />
              <Select
                mt="1"
                onChange={(e) => setRol(e.target.value)}
                variant="flushed"
              >
                <option value="0">Operador</option>
                <option value="1">Policia</option>
                <option value="2">Bombero</option>
                <option value="3">Salud</option>
                <option value="5">Admin</option>
              </Select>
              <HStack>
                <Button type="submit" colorScheme="red" variant="solid">
                  Guardar
                </Button>
                <Button
                  onClick={() => handDelete()}
                  colorScheme="red"
                  variant="solid"
                >
                  Eliminar
                </Button>
              </HStack>
            </VStack>
          </Center>
        </form>
      </Stack>
    </>
  );
};

export default EditUser;
