import React, { useEffect, useState, useCallback } from "react";
import {
    Tabla,
    Details,
    AddUser,
    PwdChange,
    EditUser,
    UserList,
    SignedUpUserList,
    Publi,
} from "../../components/";
import axios from "axios";
import {
    Center,
    Card,
    CardBody,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Collapse,
    useDisclosure,
    SimpleGrid,
} from "@chakra-ui/react";
import { usePath } from "../../contexts/PathContext";
import { useSessionStorage } from "../../assets/useSessionStorage";

const Admin = () => {
    const { CheckEnv } = usePath()
    const BASE_URL = CheckEnv()


    const [newList, setNewList] = useState([]);
    const [eventosList, setEventosList] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useSessionStorage("tabla", null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(
                    `${BASE_URL}/api/event/${1000}`
                )
                .then(async (response) => {
                    setEventosList(response.data.data);
                    setRefresh(false);
                })
                .catch((error) => {
                    console.log("Error", error);
                });
        }
        fetchData();
    }, [setEventosList, refresh, BASE_URL]);

    const handleSelectRow = (row) => {
        setSelectedRow(row);
    };

    useEffect(() => {
        if (selected === null) {
            onClose();
        }
    }, [onClose, selected]);

    const handleRefresh = useCallback(() => {
        setSelected(selectedRow);
        setRefresh(true);
    }, [selectedRow, setSelected]);

    useEffect(() => {
        const isInList = (id) => {
            return eventosList.find((item) => item.id === id);
        };

        const updateList = async () => {
            const updatedList = await Promise.all(
                eventosList.map(async (item) => {
                    const dup = isInList(item.id);
                    if (dup) {
                        const { eventos_usuarios, eventos_tipos } = dup;
                        const direccion = "hola";
                        return {
                            ...dup,
                            nombreCompleto: `${eventos_usuarios.nombre} ${eventos_usuarios.apellido}`,
                            tipoEvento: `${eventos_tipos.nombre}`,
                            direccion,
                        };
                    }
                    return null;
                })
            );
            const filteredList = updatedList.filter((item) => item !== null);
            setNewList(filteredList);
            handleSelectRow();
        };
        updateList();
    }, [eventosList]);

    return (
        <>
            <Center mt="10">
                <Card boxShadow="dark-lg" w="90%" h="100%">
                    <CardBody>
                        <Tabs colorScheme="red">
                            <TabList>
                                <Tab>Panel Usuario</Tab>
                                <Tab>Historico</Tab>
                                <Tab>Usuarios Inscriptos</Tab>
                                <Tab>Publicidad</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Tabs
                                        variant="soft-rounded"
                                        colorScheme="red"
                                    >
                                        <TabList>
                                            <Tab>Crear Usuario</Tab>
                                            <Tab>Editar Usuario</Tab>
                                            <Tab>Cambio de Contraseña</Tab>
                                            <Tab>Lista de Operadores</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <AddUser />
                                            </TabPanel>
                                            <TabPanel>
                                                <EditUser />
                                            </TabPanel>
                                            <TabPanel>
                                                <PwdChange />
                                            </TabPanel>
                                            <TabPanel>
                                                <Center>
                                                    <UserList />
                                                </Center>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </TabPanel>
                                <TabPanel>
                                    <Collapse in={isOpen} animateOpacity>
                                        <Center>
                                            <Card
                                                w="80rem"
                                                h="17rem"
                                                border="1px"
                                                borderColor="gray.200"
                                                boxShadow="xl"
                                                rounded="md"
                                                bg="white"
                                                zIndex={2}
                                            >
                                                <CardBody>
                                                    <SimpleGrid
                                                        columns={3}
                                                        spacingX="10px"
                                                        spacingY="10px"
                                                    >
                                                        {selectedRow && (
                                                            <Details
                                                                detalles={selectedRow}
                                                                conObs={false}
                                                                refresh={handleRefresh}
                                                                cerrarObs={onClose}
                                                            />
                                                        )}
                                                    </SimpleGrid>
                                                </CardBody>
                                            </Card>
                                        </Center>
                                    </Collapse>
                                    <Tabla
                                        eventos={newList}
                                        onSelectRow={handleSelectRow}
                                        isOpen={isOpen}
                                        onOpen={onOpen}
                                        onClose={onClose}
                                        setPlaySound={false}
                                        sinSonido={true}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <Center>
                                        <SignedUpUserList />
                                    </Center>
                                </TabPanel>
                                <TabPanel>
                                    <Center>
                                        <Publi />
                                    </Center>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </CardBody>
                </Card>
            </Center>
        </>
    );
};

export default Admin;
