
import React from 'react';
import {
  Box,
  Container,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';


const Footer = () => {
  return (
    <Box
      as='footer'
      pos="fixed"
      bg={useColorModeValue('#ffebee')}
      color={useColorModeValue('gray.700', 'gray.200')}
      left={0}
      bottom={0}
      right={0}
      zIndex={2}
    >
      <Container
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Text>© Todos los derechos reservados - <Link color={'blue'} href="http://www.moron.gob.ar/" isExternal>Municipio de Morón</Link></Text>
      </Container>
    </Box>
  )
}

export default Footer