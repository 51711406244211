import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { usePath } from "../../contexts/PathContext";
import axios from "axios";
import {
    Text,
    Box,
    Button,
    Stack,
    ModalOverlay,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Textarea,
    Checkbox,
    OrderedList,
    ListItem,
    FormControl,
    HStack,
    CloseButton,
    ButtonGroup
} from "@chakra-ui/react";
import { ViewPrint } from "../"

const Details = ({ detalles, conObs, refresh, cerrarObs }) => {
    const { CheckEnv } = usePath()
    const BASE_URL = CheckEnv()

    const [observacion, setObservacion] = useState("");
    const [estado, setEstado] = useState("");
    const { isLogged } = useAuth();

    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(isLogged());
    }, [isLogged]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await axios
            .put(
                `${BASE_URL}/api/event/observaciones/${data.id}`,
                {
                    texto: observacion,
                    legajo: user.legajo,
                    estado: estado ? "CERRADO" : "EN CURSO",
                }
            )
            .then((response) => {
                // setEstado(e.target.checked)
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };
    const openCase = async (event) => {
        event.preventDefault();
        await axios
            .put(
                `${BASE_URL}/api/event/reOpen/${data.id}`,

            )
            .then((response) => {
                refresh()
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };

    const [observacionesList, setObservacionesList] = useState([]);
    const data = detalles;
    const { eventos_usuarios } = data;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    async function fetchData() {
        await axios
            .get(
                `${BASE_URL}/api/event/observaciones/${data.id}`
            )
            .then(async (response) => {
                setObservacionesList(response.data.data[0]);
            })
            .catch((error) => {
                console.log("Error", error);
            });
    }

    async function abrirModal() {
        await fetchData();
        onOpen();
    }

    function cerrarModal() {
        refresh();
        onClose();
    }

    function cerrarObservaciones() {
        refresh();
        cerrarObs();
    }

    const formatText = (text) => {
        return JSON.stringify(text).replace(/"/g, "");
    };
    console.log(observacionesList)
    const listObs = observacionesList ? (
        observacionesList.map((obs) => (
            <Box key={obs.fecha} mb={2}>

                <ListItem mb={2}>
                    <HStack>
                        <Text>{formatText(obs.fecha)}</Text>
                        <Text as="b" mr={2}>
                            Legajo:{" "}
                        </Text>
                        <Text>{formatText(obs.legajo)}</Text>
                        <Text as="b" mr={2}>
                            Estado:{" "}
                        </Text>
                        <Text>{formatText(obs.estado)}</Text>
                    </HStack>
                </ListItem>


                <HStack>
                    <Text>{formatText(obs.texto)}</Text>
                </HStack>

            </Box>
        ))
    ) : (
        <ListItem>No hay observaciones</ListItem>
    );

    return (
        <>
            <Box columns={2} p="6">
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Evento ID:{" "}
                    </Text>
                    <Text fontSize="xl">{data.id} <Text as="b"> - {data.tipoEvento}</Text></Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Nombre:{" "}
                    </Text>
                    <Text fontSize="xl">{data.nombreCompleto}</Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Calle:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.dir_calle}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Altura:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.dir_altura}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Localidad:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.dir_localidad}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Tel:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.telefono}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Evento Calle:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {data.dir_calle}
                    </Text>
                </Stack>
            </Box>

            <Box columns={2} p="6">
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        DNI:{" "}
                    </Text>
                    <Text fontSize="xl">{eventos_usuarios.dni}</Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Email:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.email}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Genero:
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.genero}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Tel Ref:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {" "}
                        {eventos_usuarios.telefono_persona_referencia}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Per Ref:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.persona_referencia}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Tel Alt:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {eventos_usuarios.telefono_alternativo}
                    </Text>
                </Stack>
                <Stack direction={["column", "row"]} spacing="15px">
                    <Text mb={2} as="b" fontSize="xl">
                        Evento Altura:{" "}
                    </Text>
                    <Text mb={2} fontSize="xl">
                        {data.dir_nro}
                    </Text>
                </Stack>
            </Box>
            <Box pos="absolute" bottom="17rem" right="0.5rem" top={!conObs ? "20px" : ""}>
                <ButtonGroup>
                    {data.estado === "CERRADO" && !conObs && (
                        <Button
                            ms={2}
                            colorScheme="red"
                            size="md"
                            onClick={openCase}
                        >Reabrir Caso</Button>)}
                    <Button
                        ms={2}
                        colorScheme="blue"
                        size="md"
                        onClick={abrirModal}
                    >
                        Atencion Evento
                    </Button>
                    <CloseButton onClick={cerrarObservaciones} />
                </ButtonGroup>
            </Box>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                size={"6xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <Stack direction={["column", "row"]} spacing="15px">
                        <ModalHeader>Observaciones</ModalHeader>
                        <Box mt={2}>
                            <ViewPrint
                                detalles={detalles}
                                observaciones={observacionesList}
                            />
                        </Box>

                    </Stack>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <OrderedList mb={2}>{listObs}</OrderedList>
                        {data.estado !== "CERRADO" && conObs && (
                            <form onSubmit={handleSubmit}>
                                <FormControl isRequired>
                                    <Textarea
                                        mb={3}
                                        placeholder="Escriba aqui las observaciones del  Evento"
                                        onChange={(e) =>
                                            setObservacion(e.target.value)
                                        }
                                    />
                                </FormControl>
                                <Checkbox
                                    onChange={(e) =>
                                        setEstado(e.target.checked)
                                    }
                                    colorScheme="green"
                                    size="lg"
                                >
                                    Cerrar Evento
                                </Checkbox>
                                <Button
                                    type="submit"
                                    colorScheme="blue"
                                    ml={30}
                                    mr={0}
                                    onClick={cerrarModal}
                                >
                                    Guardar
                                </Button>

                            </form>
                        )}

                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    );
};

export default Details;
