import {
  Button, AvatarGroup, Avatar, Center,
  Input, Card, CardBody, FormControl, InputGroup,
  InputRightElement, IconButton, useDisclosure
} from '@chakra-ui/react'
import axios from 'axios';
import { useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useAuth } from '../../contexts/AuthContext';
import { Alertmsg } from '../../components'
import { usePath } from '../../contexts/PathContext';

const Login = () => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [legajo, setLegajo] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [msg, setMsg] = useState(null);

  const { isOpen, onToggle } = useDisclosure()

  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMsg(null);
    const data = { 'legajo': legajo, 'password': password }

    try {
      const response = await axios.post(`${BASE_URL}/api/login/loginProcess`, data)
      const { message } = response.data
      setLoading(false);
      if (message === "loginSuccess") {
        setMsg('Estás logueado');
        setCode('success')
        login(data.legajo)
      } else {
        setMsg('Legajo o contraseña incorrectos')
        setCode('error');

      }
    } catch (err) {
      console.error(err);
      setMsg('Hubo un error al intentar loguearse, intente nuevamente más tarde');
      setCode('error');
      setLoading(false);
    }

  };

  return (
    <>
      <Alertmsg msg={msg} code={code} />
      <Center mt="10">
        <Card boxShadow='dark-lg' w="20rem" h="23rem" >
          <CardBody>
            <Center>
              <AvatarGroup spacing="1rem">
                <Avatar mt={5} bg="#c80f2e" w="5rem" h="5rem" />
              </AvatarGroup>
            </Center>
            <form onSubmit={handleSubmit}>
              <Center>
                <FormControl isRequired>
                  <Input placeholder="Usuario"
                    _placeholder={{ opacity: 1, color: 'gray.500' }}
                    id="usuario" type="user" mt="5"
                    onChange={(e) => setLegajo(e.target.value)}
                  />
                </FormControl>
              </Center>
              <Center>
                <FormControl isRequired>
                  <InputGroup mt="5">
                    <InputRightElement>
                      <IconButton
                        variant="link"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={() => onToggle()}
                      />
                    </InputRightElement>
                    <Input
                      id="password"
                      name="password"
                      type={isOpen ? 'text' : 'password'}
                      autoComplete="current-password"
                      placeholder="Contraseña"
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Center>
              <Center>
                <Button type="submit"
                  isLoading={loading}
                  loadingText="Cargando..."
                  size="lg"
                  mt="5"
                  colorScheme="red"
                >
                  Iniciar Sesion
                </Button>
              </Center>
            </form>
          </CardBody>
        </Card>
      </Center>
    </>
  )
}

export default Login