import { createContext, useCallback, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "../assets/useSessionStorage";
import axios from "axios";
import { usePath } from "./PathContext";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [fullUser, setFullUser] = useSessionStorage("usuario", null);
  const [user, setUser] = useSessionStorage("legajo", null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user === null || fullUser !== null) return;

      try {
        const response = await axios.get(`${BASE_URL}/api/login/${user}`)
        const [userData] = response.data.data
        const nombre = `${userData.nombre} ${userData.apellido}`
        setFullUser({ legajo: user, nombre: nombre, rol_id: userData.rol_id })
      } catch (err) {
        console.error(err);
      }
    }

    fetchUserData();
  }, [user, setFullUser, fullUser, BASE_URL]);

  // call this function when you want to authenticate the user
  const login = useCallback(async (legajo) => {
    await setUser(legajo);
    navigate("/home")
  }, [navigate, setUser])

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setUser(null);
    setFullUser(null);
    navigate("/", { replace: true });
  }, [navigate, setUser, setFullUser])

  const isLogged = useCallback(() => {
    return fullUser
  }, [fullUser])

  const value = useMemo(
    () => ({
      user,
      fullUser,
      login,
      logout,
      isLogged
    }),
    [user, fullUser, login, logout, isLogged]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
