import { Button, Center, Input, VStack, InputGroup, InputRightElement } from '@chakra-ui/react'
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Alertmsg } from '../'
import axios from 'axios';
import React, { useState } from 'react'
import { usePath } from '../../contexts/PathContext';

const PwdChange = () => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [code, setCode] = useState(null);
  const [msg, setMsg] = useState(null);
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const handleClick = () => setShow(!show)
  const handleClick1 = () => setShow1(!show1)
  const [legajo, setLegajo] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(`${BASE_URL}/api/login/resetPassAdmin/` + legajo, { 'password1': password1, 'password2': password2 })

      const { message } = response.data

      if (message === "userResetPassOK") {
        setMsg('Cambio de contraseña realizado');
        setCode('success')

      } else {
        setMsg('Error al cambiar la contraseña')
        setCode('error');
      }
    } catch (err) {
      console.error(err);
    }

  };

  return (
    <VStack>
      <Alertmsg msg={msg} code={code} />
      <form onSubmit={handleSubmit}>
        <Center>
          <Input w={300} placeholder="Legajo" onChange={(e) => setLegajo(e.target.value)} />
        </Center>
        <Center>

          <InputGroup size='md' mt={5}>

            <Input
              type={show ? 'text' : 'password'}
              placeholder="Contraseña"
              onChange={(e) => setPassword1(e.target.value)}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={handleClick}>
                {show ? <HiEye /> : <HiEyeOff />}
              </Button>
            </InputRightElement>
          </InputGroup>

        </Center>
        <Center>
          <InputGroup size='md' mt={5}>

            <Input
              type={show1 ? 'text' : 'password'}
              placeholder="Repetir Contraseña"
              onChange={(e) => setPassword2(e.target.value)}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={handleClick1}>
                {show1 ? <HiEye /> : <HiEyeOff />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Center>
        <Center>
          <Button type='submit' size="sm" mt="5" colorScheme="red">
            Guardar
          </Button>
        </Center>
      </form>
    </VStack>
  )
}

export default PwdChange