import { Text } from "@chakra-ui/react";

export const userColumns = [
    {
        Header: "ID evento",
        accessor: "id",
    },
    {
        Header: "Fecha",
        accessor: "fecha",
    },
    {
        Header: "Tipo de Evento",
        accessor: "tipoEvento",
    },
    {
        Header: "Nombre",
        accessor: "nombreCompleto",
    },
    {
        Header: "Estado",
        accessor: "estado",
        Cell: (props) => {
            const { value } = props;
            return <Text as="b">{value}</Text>;
        },
    },
];
