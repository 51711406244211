import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Login, Admin, Unsub } from "./pages";
import { Footer, Header } from "./components";
import { ProtectedRoute, AdminRoute } from "./middleware";
import "animate.css";

function App() {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route
                    path="/home"
                    element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin"
                    element={
                        <AdminRoute>
                            <Admin />
                        </AdminRoute>
                    }
                />
                <Route path="/unsub" element={<Unsub />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
