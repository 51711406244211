import { Button, Center, Input, VStack, InputGroup, InputRightElement, Select } from '@chakra-ui/react'
import React, { useState } from 'react'
import axios from 'axios';
import md5 from 'md5';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Alertmsg } from '../';
import { usePath } from '../../contexts/PathContext';


const AddUser = () => {
  const { CheckEnv } = usePath()
  const BASE_URL = CheckEnv()


  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const [legajo, setLegajo] = useState('');
  const [password, setPassword] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [rol, setRol] = useState('');
  const [code, setCode] = useState(null);
  const [msg, setMsg] = useState(null);

  const setNull = () => {
    setNombre('')
    setApellido('')
    setLegajo('')
    setPassword('')
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/login/add`,
        { 'legajo': legajo, 'nombre': nombre, 'apellido': apellido, 'password': md5(password), 'rol_id': rol, }
      )
      const { message } = response.data

      if (message === "loginAddOk") {
        setMsg('Legajo creado con exito');
        setCode('success')
        setNull()

      } else {
        setMsg('No se pudo crear el legajo')
        setCode('error');
        setNull()
      }
    } catch (err) {
      console.error(err);
    }

  };
  return (
    <VStack>
      <Alertmsg msg={msg} code={code} />
      <form onSubmit={handleSubmit}>
        <Center>
          <Input value={legajo} placeholder="Legajo" mt="5" onChange={(e) => setLegajo(e.target.value)} />
        </Center>
        <Center>
          <Input value={nombre} placeholder="Nombre" mt="5" onChange={(e) => setNombre(e.target.value)} />
        </Center>
        <Center>
          <Input value={apellido} placeholder="Apellido" mt="5" onChange={(e) => setApellido(e.target.value)} />
        </Center>
        <Center>
          <InputGroup size='md' mt="5">
            <Input
              type={show ? 'text' : 'password'}
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={handleClick}>
                {show ? <HiEye /> : <HiEyeOff />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Center>
        <Center>
          <Select mt="1" onChange={(e) => setRol(e.target.value)} variant='flushed'>
            <option value='0'>Operador</option>
            <option value='1'>Policia</option>
            <option value='2'>Bombero</option>
            <option value='3'>Salud</option>
            <option value='5'>Admin</option>
          </Select>
        </Center>
        <Center>
          <Button type='submit' size="sm" mt="5" colorScheme="red">
            Guardar
          </Button>
        </Center>
      </form>
    </VStack>
  )
}

export default AddUser