import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useSessionStorage } from "../assets/useSessionStorage";

export const AdminRoute = ({ children }) => {
  const [fullUser] = useSessionStorage("usuario");
  const { user } = useAuth();

  if (user && fullUser.rol_id === 5) {
    return children;
  }
  return <Navigate to="/home" />;
};