import {
    Box,
    Image,
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Portal,
    Flex,
    Spacer,
    useDisclosure,
    Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import logo from "../../img/logo_moron.png";
import PwdChangeUser from "../PwdChangeUser";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLogged } = useAuth();
    const { logout } = useAuth();

    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(isLogged());
    }, [isLogged]);

    return (
        <>
            <Flex bg="#c80f2e">
                <Box bg="#c80f2e" w="100%" h="6.5rem" p={2} color="white">
                    <Box>
                        <Link to="/home">
                            <Image src={logo} alt="Logo" h="80px" />
                        </Link>
                    </Box>
                </Box>
                <Spacer />
                {user && (
                    <Box bg="#c80f2e" color="white" mr={5}>
                        <Menu>
                            <MenuButton>
                                <Avatar m={5} mb={0} bg="#c80f2e" />
                                <Text w={150}>{user.nombre}</Text>
                            </MenuButton>
                            <Portal>
                                <MenuList zIndex={2} m={2}>
                                    <MenuItem onClick={onOpen}>
                                        Cambiar Contraseña
                                    </MenuItem>
                                    {user.rol_id === 5 && (
                                        <MenuItem as={Link} to="/admin">
                                            Admin
                                        </MenuItem>
                                    )}
                                    {user.rol_id === 5 && (
                                        <MenuItem as={Link} to="/home">
                                            Inicio
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={logout}>
                                        Cerrar sesion
                                    </MenuItem>
                                </MenuList>
                            </Portal>
                        </Menu>
                    </Box>
                )}
                <PwdChangeUser
                    onOpen={onOpen}
                    onClose={onClose}
                    isOpen={isOpen}
                />
            </Flex>
        </>
    );
};

export default Header;
