import React, { useEffect, useRef, useMemo } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const API_KEY = "AIzaSyBU9Ow1PqNUZFr2MfVuUuKUqyh6rPy2KY0";
const loader = new Loader({
    apiKey: API_KEY,
    version: "weekly",
});

export const Map = ({ lat, lng }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const mapCenter = useMemo(() => ({ lat, lng }), [lat, lng]);
    const markerCoordinates = useMemo(() => ({ lat, lng }), [lat, lng]);

    useEffect(() => {
        loader.load().then(() => {
            const mapOptions = {
                zoom: 15,
                center: mapCenter,
            };

            const map = new window.google.maps.Map(mapRef.current, mapOptions);

            const marker = new window.google.maps.Marker({
                position: markerCoordinates,
                map: map,
            });

            markerRef.current = marker;
        });
    }, [mapCenter, markerCoordinates]);

    return (
        <div ref={mapRef} style={{ width: "100%", height: "17.6rem" }}></div>
    );
};

export default Map;
